import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

const paths = {
  en: "/en/contact",
  it: "/it/contatto",
  br: "/br/contato",
  fr: "/fr/contactez",
  de: "/de/kontakt",
  es: "/contacto",
}

export default props => (
  <>
    <Layout
      image="/android-chrome-512x512.png"
      lang={props.pageContext.lang}
      title={props.data.allContentfulContact.nodes[0].title}
      description={props.data.allContentfulContact.nodes[0].description}
      paths={paths}
      pageType="contact"
    >
      <div className="container mt-5">
        <h1 className="text-center">
          {props.data.allContentfulContact.nodes[0].title}
        </h1>
        <p className="text-center">
          {props.data.allContentfulContact.nodes[0].header}
        </p>
        <div className="row mt-5">
          <div
            className="col-md-6 offset-md-3"
            dangerouslySetInnerHTML={{
              __html:
                props.data.allContentfulContact.nodes[0].subheader.content[0]
                  .content[0].value,
            }}
          ></div>
        </div>
      </div>
    </Layout>
  </>
)

export const query = graphql`
  query($lang: String!) {
    allContentfulContact(filter: { lang: { eq: $lang } }) {
      nodes {
        title
        header
        subheader {
          content {
            content {
              value
            }
          }
        }
      }
    }
  }
`
